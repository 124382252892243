import Aos from "aos";

export function INIT_ANIMATE_ON_SCROLL() {
  Aos.init({
    duration: 1200,
    easing: 'cubic-bezier(.15,.8,.4,1)',
    startEvent: 'load',
    disableMutationObserver: false,
    delay: 0,
    once: true
  });

  Aos.refresh(true);

  window.addEventListener('DOMContentLoaded', () => Aos.refresh(true));
  window.addEventListener('load', () => Aos.refresh(true));
}
